.chat-container {
  background-color: white;
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  max-width: 600px; /* Adjust based on preference */
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 0 0 0; /* 20 pixels padding on top and bottom */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: white;
  color: teal; /* Teal text color */
  text-align: center; /* Center the text */
  padding: 10px 0 25px 0; /* Approximately 70px height with padding */
  font-size: 24px; /* Adjust font size as needed */
  font-weight: 600; /* Adjust font weight as needed */
  border-bottom: 1px solid #e0e0e0; /* Light grey bottom border */
}

.messages {
  padding: 20px 5px 10px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 75%;
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 20px;
  color: white;
  white-space: pre-wrap;
  position: relative; /* For the message tail */
}

.inbound {
  background-color: #e0e0e0;
  color: black;
  align-self: flex-start;
  border-bottom-left-radius: 0; /* Tail effect for inbound messages */
  text-align: left;
}

.outbound {
  background-color: teal;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 0; /* Tail effect for outbound messages */
  text-align: right;
}

/* Tail for inbound messages */
.inbound::after {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left: 0;
  border-bottom: 0;
  margin-top: -5px;
  margin-left: 0px;
}

/* Tail for outbound messages */
.outbound::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 0;
  border-bottom: 0;
  margin-top: -5px;
  margin-right: 0px;
}

.input-bar {
  display: flex;
  align-items: flex-end;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
}

.input-text {
  flex-grow: 1;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  line-height: 18px; /* Line height for single line */
  min-height: 18px; /* Minimum height for one line */
  max-height: 90px; /* Maximum height for about 5 lines */
  overflow-y: auto; /* Allow scrolling for overflow */
  font-family: inherit; /* Ensure font consistency */
  word-wrap: break-word; /* Ensure proper word wrapping */
  outline: none; /* Remove default focus outline */
  text-align: left; /* Left align text */
}

.input-text[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: grey; /* Placeholder text color */
}

.send-button {
  background-color: teal;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  margin-left: 10px;
  cursor: pointer;
  align-self: stretch; /* Keep the button size consistent */
  height: 36px; /* Fixed height for the button */
  align-self: flex-end; /* Align button vertically center in the input bar */
}

.send-button:hover {
  background-color: darken(teal, 10%);
}

.input-bar textarea {
  flex-grow: 1;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  resize: none; /* Prevent manual resizing */
  overflow-y: auto; /* Allow scrolling */
  min-height: 0px; /* Minimum height for one line */
  max-height: 90px; /* Maximum height for about 5 lines */
}

.input-bar button {
  background-color: teal;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  margin-left: 10px;
  cursor: pointer;
}

.input-bar button:hover {
  background-color: darken(teal, 10%);
}
