@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@property --primary-color {
  syntax: "<color>";
  initial-value: #fe2f87;
}

@property --secondary-color {
  syntax: "<color>";
  initial-value: #ffa500;
}

@font-face {
  font-family: "Cabinet Grotesk Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Regular"),
    url("fonts/CabinetGrotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Thin"),
    url("fonts/CabinetGrotesk-Thin.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Extralight";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Extralight"),
    url("fonts/CabinetGrotesk-Extralight.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Light";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Light"),
    url("fonts/CabinetGrotesk-Light.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Medium"),
    url("fonts/CabinetGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Bold"),
    url("fonts/CabinetGrotesk-Bold.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Extrabold";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Extrabold"),
    url("fonts/CabinetGrotesk-Extrabold.woff") format("woff");
}

@font-face {
  font-family: "Cabinet Grotesk Black";
  font-style: normal;
  font-weight: normal;
  src: local("Cabinet Grotesk Black"),
    url("fonts/CabinetGrotesk-Black.woff") format("woff");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Cabinet Grotesk Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-bold {
  font-family: "Cabinet Grotesk Bold";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-primary {
  background-color: var(--primary-color);
}

.text-primary {
  color: var(--primary-color);
}

.fill-primary {
  fill: var(--primary-color);
}

.border-primary {
  border-color: var(--primary-color);
}

.ring-primary {
  --tw-ring-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.fill-secondary {
  fill: var(--secondary-color);
}

@keyframes fly-in-bottom {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

.fly-in-bottom {
  animation: fly-in-bottom 0.3s ease-out;
}
