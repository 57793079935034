.chat-input {
    line-height: 20px; /* Line height for single line */
    padding: 5px;
    max-height: 200px; /* Maximum height for about 5 lines */
    overflow-y: auto; /* Allow scrolling for overflow */
    font-family: inherit; /* Ensure font consistency */
    word-wrap: break-word; /* Ensure proper word wrapping */
    outline: none; /* Remove default focus outline */
    text-align: left; /* Left align text */
}

.chat-input[data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: grey; /* Placeholder text color */
}

.fly-in-left {
    animation: fly-in-left 0.5s ease-out;
}

@keyframes fly-in-left {
    0% {
        transform: translateX(200%);
    }
    100% {
        transform: translateX(0);
    }
}

.fly-in-right {
    animation: fly-in-right 0.5s ease-out;
}

@keyframes fly-in-right {
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(0);
    }
}

.blink-dot {
    animation: blink-dot 1s infinite;
    width: 10px;
    height: 10px;
    background-color: #000;
    margin-left: 2px;
    display: inline-block;
    border-radius: 50%;
}

.blink-dot:nth-child(3) {
    animation-delay: 250ms;
}

.blink-dot:nth-child(2) {
    animation-delay: 500ms;
}

@keyframes blink-dot {
    0%,
    100% {
        opacity: 0;
        transform: scale(1) translateY(-50%);
    }
    50% {
        opacity: 1;
        transform: scale(0.5) translateY(5px);
    }
}

.fade-in {
    animation: fade-in 1s ease-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
} /* New styles for links */
.chat-links-list {
    list-style-type: none;
    padding-left: 0;
}

.chat-link {
    color: #0000ee;
    text-decoration: underline;
}

.chat-link:visited {
    color: #551a8b;
}

.chat-link:hover,
.chat-link:focus {
    color: #0000ee;
    text-decoration: none;
}

.document-link {
    color: #0000ee;
    text-decoration: underline;
}

.document-link:hover,
.document-link:focus {
    text-decoration: none;
}

/* New styles for doc attachments */
.document-card {
    display: inline-block;
    width: 100px; /* Keep the reduced width */
    height: 120px; /* Set a fixed height to make it more square */
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin: 5px;
    padding: 5px; /* Reduce the padding */
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ensure content does not overflow the card */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure the content is distributed correctly */
}

.document-card img {
    width: 20px; /* Keep the reduced image size */
    height: 20px;
    margin: 0 auto; /* Center the image horizontally */
}

.document-card .document-name {
    font-size: 10px; /* Keep the reduced font size */
    color: #333;
    word-wrap: break-word;
    max-height: 30px; /* Adjust the height to fit the text */
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long text with ellipsis */
    margin: 2px 0; /* Reduce the margin for spacing */
}

.document-card a {
    display: block;
    text-decoration: none;
    color: #4285f4;
    font-size: 10px; /* Keep the reduced font size */
    margin: 2px 0; /* Reduce the margin above the link */
}

.document-cards-container {
    display: flex;
    flex-wrap: wrap;
}
